import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import BackgroundImage from 'gatsby-background-image';
import CallToScrollDown from '@components/CallToScrollDown/CallToScrollDown';
import {
    useIsMobile,
    useIsDesktop,
    useIsTablet,
} from '../../hooks/useIsDevice';
// Styles
import './HeaderMinimal.scss';

const HeaderMinimalBgImage = ({ imageSharp, imageUrl, className }) => {
    return imageSharp?.childImageSharp ? (
        <>
            <BackgroundImage
                fluid={imageSharp.childImageSharp.fluid}
                className={className}
            />
            <div className="Pseudo--bg" />
        </>
    ) : (
        <div
            className={className}
            style={{ backgroundImage: `url(${imageUrl})` }}
        />
    );
}

function HeaderMinimal(props) {
    const mod_class = [
        'HeaderMinimal',
        props.size ? 'size-' + props.size : ' ',
    ].join(' ');

    const { desktopImage, tabletImage, mobileImage } = props;

    const tabletImgUrl = tabletImage ? tabletImage : desktopImage;
    const mobileImgUrl = mobileImage ? mobileImage : desktopImage;

    const isDesktop = useIsDesktop();
    const isTablet = useIsTablet();
    const isMobile = useIsMobile();
    
    return (
        <div className={mod_class}>
            {isDesktop && (
                <HeaderMinimalBgImage
                    imageSharp={desktopImage}
                    imageUrl={desktopImage}
                    className="HeaderMinimal--bg is-desktop"
                />
            )}
            {isTablet && (
                <HeaderMinimalBgImage
                    imageSharp={tabletImgUrl}
                    imageUrl={tabletImgUrl}
                    className="HeaderMinimal--bg is-tablet"
                />
            )}
            {isMobile && (
                <HeaderMinimalBgImage
                    imageSharp={mobileImgUrl}
                    imageUrl={mobileImgUrl}
                    className="HeaderMinimal--bg is-mobile"
                />
            )}
            <Section
                container={props.container}
                spacing={props.spacing}
                className="HeaderMinimalV2--innerWraper"
            >
                <div className="HeaderMinimalV2--inner">
                    <Title
                        tag="h1"
                        size={
                            props.titleSize
                                ? props.titleSize
                                : props.subtitle
                                ? 'xxl'
                                : 'giant'
                        }
                        font="alt"
                        align="center"
                        theme="white"
                    >
                        {props.title}
                    </Title>
                    {props.subtitle && (
                        <div className="HeaderMinimal--subtitle">
                            <div className="HeaderMinimal--subtitleInner">
                                <Title
                                    tag="h2"
                                    theme="white"
                                    size="m"
                                    align="center"
                                    noMargin
                                >
                                    {props.subtitle}
                                </Title>
                            </div>
                        </div>
                    )}

                    <Text theme="white">{props.text}</Text>
                    <Divider spacing="xs" invisible />
                    <div className="HeaderMinimalV2--ctaGroup">
                        <div className="HeaderMinimal--btnPrimary">
                            {props.btnPrimary}
                        </div>

                        {props.btnSecondary && (
                            <div className="HeaderMinimal--btnSecondary">
                                {props.btnSecondary}
                            </div>
                        )}
                    </div>
                </div>
            </Section>

            {props.callToScroll && (
                <div className="HeaderMinimal--CallToScroll">
                    <CallToScrollDown align="center" theme="white" />
                </div>
            )}
        </div>
    );
}

export default HeaderMinimal;

import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Layout from '@components/layout';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Section from '@atoms/Section/Section';
import CMSHeaderMinimal from '@components/HeaderMinimal/HeaderMinimalV2';
import VideoBackgroundWithContent from '@components/VideoBackgroundWithContent';
import Button from '@atoms/Button/Button';
import Seo from '@components/seo';
import {
    ChapterUSP,
    SocialProof,
    ProviderList,
    StepByStep,
    CardSliderWithImageBackground,
    ChapterPricing,
    RelatedContent,
    CommonActionButton,
    TherapyOrCoach,
} from '@components/CMSComponents';
import Footer from '@components/Footer/Footer';
import KeyFeaturesSlider from '@components/KeyFeaturesSlider';
import TitleGroup from '@atoms/TitleGroup/TitleGroup';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import './Chapter.scss';
import LanguageNotSupported from '@components/LanguageNotSupported';
import useLocale from '@hooks/useLocale';
import { getOriginalLocale } from '@helpers/GlobalHelpers';
import { FiShare } from 'react-icons/fi';
import CopyUrlClipboard from '../../CopyUrlClipboard/CopyUrlClipboard';
import { GenericIcon } from '../../CMSComponents';
import { withLocale } from '@hocs';

export const query = graphql`
    query($language: String!, $id: ID!) {
        aepsyCms {
            chapter(id: $id) {
                data {
                    id
                    attributes {
                        slug
                        name
                        seoTitle
                        seoDescription
                        locale
                        header {
                            id
                            title
                            subtitle
                            ctaUrl
                            ctaLabel
                            secondaryCtaLabel
                            secondaryCtaUrl
                            images {
                                media
                                src {
                                    data {
                                        attributes {
                                            url
                                            urlSharp {
                                                childImageSharp {
                                                    fluid(
                                                        maxWidth: 1280
                                                        toFormat: WEBP
                                                    ) {
                                                        ...GatsbyImageSharpFluid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        chapterUsp {
                            ...ChapterUsp
                        }
                        socialProof {
                            author
                            quote
                            comments {
                                text
                            }
                            audio {
                                data {
                                    attributes {
                                        url
                                    }
                                }
                            }
                            audioMoreInfo {
                                data {
                                    attributes {
                                        component {
                                            ... on AEPSYCMS_ComponentCommonCardInfoList {
                                                title
                                                content
                                                image {
                                                    data {
                                                        attributes {
                                                            url
                                                            urlSharp {
                                                                childImageSharp {
                                                                    fluid(
                                                                        maxWidth: 60
                                                                        toFormat: WEBP
                                                                    ) {
                                                                        ...GatsbyImageSharpFluid
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        providersSlider {
                            ...ProvidersSlider
                        }
                        stepByStep {
                            title
                            subtitle
                            list {
                                title
                                subtitle
                                icon
                            }
                            ctaUrl
                            ctaLabel
                        }
                        cardsSlider {
                            title
                            subtitle
                            components {
                                data {
                                    attributes {
                                        component {
                                            ... on AEPSYCMS_ComponentCommonFlipCard {
                                                id
                                                quote
                                                title
                                                content
                                                images {
                                                    media
                                                    src {
                                                        data {
                                                            attributes {
                                                                url
                                                                urlSharp {
                                                                    childImageSharp {
                                                                        fluid(
                                                                            maxWidth: 350
                                                                            toFormat: WEBP
                                                                        ) {
                                                                            ...GatsbyImageSharpFluid
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        pricing {
                            title
                            subtitle
                            pricingTitle
                            pricingSubtitle
                            secondaryCtaLabel
                            secondaryCtaUrl
                            list {
                                text
                            }
                            ctaUrl
                            ctaLabel
                            images {
                                media
                                src {
                                    data {
                                        attributes {
                                            url
                                            urlSharp {
                                                childImageSharp {
                                                    fluid(
                                                        maxWidth: 300
                                                        toFormat: WEBP
                                                        webpQuality: 90
                                                        fit: CONTAIN
                                                    ) {
                                                        ...GatsbyImageSharpFluid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        faq {
                            data {
                                attributes {
                                    key
                                    qar {
                                        id
                                        question
                                        answer
                                    }
                                    title
                                    subtitle
                                }
                            }
                        }
                        therapyOrCoach {
                            data {
                                attributes {
                                    component {
                                        ... on AEPSYCMS_ComponentCommonTherapyOrCoach {
                                            title
                                            cards {
                                                id
                                                title
                                                subtitle
                                                content
                                                cta {
                                                    label
                                                    to
                                                }
                                                image {
                                                    data {
                                                        attributes {
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        relatedContent {
                            title
                            components {
                                data {
                                    attributes {
                                        component {
                                            ... on AEPSYCMS_ComponentAtomsButton {
                                                theme
                                                label
                                                to
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        aepsyUsp {
                            data {
                                attributes {
                                    component {
                                        ... on AEPSYCMS_ComponentCommonAepsyUsp {
                                            title
                                            subtitle
                                            content
                                            icon
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const ChapterPage: React.FC<any> = ({ data }) => {
    const { currentLanguage } = useLocale();
    const pageData = data.aepsyCms.chapter.data

    const renderHeaderBtnSecondary = (
        secondaryCtaLabel?: string,
        secondaryCtaUrl?: string
    ) => {
        if (!secondaryCtaLabel || !secondaryCtaUrl) return <></>;

        if (secondaryCtaUrl.includes('share')) {
            return (
                <CopyUrlClipboard
                    url={typeof window !== 'undefined' && window.location.href}
                >
                    <Button
                        variant="naked"
                        align="ceter"
                        theme="white"
                        label={header.secondaryCtaLabel}
                        size="m"
                        icon={<FiShare />}
                        className="Chapter--shareBtn"
                    />
                </CopyUrlClipboard>
            );
        }

        if (secondaryCtaUrl.includes('#')) {
            return (
                <AnchorLink offset={() => 40} href={secondaryCtaUrl}>
                    <Button
                        variant="naked"
                        theme="white"
                        label={header.secondaryCtaLabel}
                        align="ceter"
                        size="m"
                    />
                </AnchorLink>
            );
        }

        return (
            <a href={secondaryCtaUrl}>
                <Button
                    variant="naked"
                    align="ceter"
                    theme="white"
                    label={header.secondaryCtaLabel}
                    size="m"
                    className="Chapter--shareBtn"
                />
            </a>
        );
    };

    const cmsData = useMemo(() => {
        if (
            !pageData ||
            (pageData &&
                getOriginalLocale(pageData?.attributes.locale) !==
                    currentLanguage)
        )
            return null;

        const {
            attributes: {
                header,
                chapterUsp,
                socialProof,
                providersSlider,
                stepByStep,
                cardsSlider,
                pricing,
                faq,
                therapyOrCoach,
                relatedContent,
                seoTitle,
                seoDescription,
                aepsyUsp,
            },
        } = pageData;

        const headerDesktopImage = header?.images.find(
            (item: any) => item.media === 'desktop'
        );
        const mobileDesktopImage = header?.images.find(
            (item: any) => item.media === 'mobile'
        );

        const pricingDesktopImage = pricing?.images.find(
            (item: any) => item.media === 'desktop'
        );
        const pricingMobileImage = pricing?.images.find(
            (item: any) => item.media === 'mobile'
        );

        const relatedContentData = relatedContent?.components?.data
            ? relatedContent?.components?.data[0]
            : undefined;
        const relatedContentCards = relatedContentData
            ? relatedContentData?.attributes?.component
            : [];

        return {
            aepsyUsp: aepsyUsp?.data
                ? aepsyUsp?.data?.attributes?.component?.map(
                      ({ title, subtitle, content, icon }: any) => ({
                          title,
                          subtitle,
                          text: content,
                          icon: <GenericIcon icon={icon} />,
                      })
                  )
                : [],
            headerDesktopImage,
            mobileDesktopImage,
            header,
            chapterUsp: chapterUsp?.list,
            chapterCta: {
                ctaLabel: chapterUsp?.ctaLabel,
                ctaUrl: chapterUsp?.ctaUrl,
            },
            socialProof,
            providersSlider,
            stepByStep,
            cardsSlider,
            pricing,
            pricingDesktopImage,
            pricingMobileImage,
            faq,
            therapyOrCoach,
            relatedContentTitle: relatedContent ? relatedContent.title : null,
            relatedContentCards,
            seoTitle,
            seoDescription,
        };
    }, [pageData, currentLanguage]);

    if (!pageData || !cmsData || !cmsData?.header) {
        return (
            <Layout>
                <div className="global_theme-green">
                    <HeaderNav theme="dark" />
                    <Section spacingBottom="m">
                        <LanguageNotSupported />
                    </Section>
                    <Footer />
                </div>
            </Layout>
        );
    }

    const {
        headerDesktopImage,
        mobileDesktopImage,
        header,
        chapterUsp,
        chapterCta,
        socialProof,
        providersSlider,
        stepByStep,
        cardsSlider,
        pricing,
        pricingDesktopImage,
        pricingMobileImage,
        faq,
        therapyOrCoach,
        relatedContentTitle,
        relatedContentCards,
        seoTitle,
        seoDescription,
        aepsyUsp,
    } = cmsData;

    return (
        <Layout>
            <Seo title={seoTitle} description={seoDescription} />
            <div className="global_theme-green Chapter">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <CMSHeaderMinimal
                        theme="light"
                        desktopImage={
                            headerDesktopImage.src.data.attributes.urlSharp
                                ? headerDesktopImage.src.data.attributes.urlSharp
                                : headerDesktopImage.src.data.attributes.url
                        }
                        mobileImage={
                            mobileDesktopImage.src.data.attributes.urlSharp
                                ? mobileDesktopImage.src.data.attributes.urlSharp
                                : mobileDesktopImage.src.data.attributes.url
                        }
                        title={header.title}
                        subtitle={header.subtitle}
                        spacing="l"
                        btnPrimary={
                            <CommonActionButton
                                cta={{
                                    ctaLabel: header.ctaLabel,
                                    ctaUrl: header.ctaUrl,
                                }}
                            />
                        }
                        btnSecondary={renderHeaderBtnSecondary(
                            header.secondaryCtaLabel,
                            header.secondaryCtaUrl
                        )}
                        callToScroll
                    />
                </Section>
                <div
                    id={
                        header?.secondaryCtaUrl?.includes('#')
                            ? header?.secondaryCtaUrl?.replace('#', '')
                            : 'ChapterPage'
                    }
                >
                    {chapterUsp && (
                        <Section spacingTop="m" spacingBottom="s">
                            <ChapterUSP uspList={chapterUsp} cta={chapterCta} />
                        </Section>
                    )}
                    {socialProof && (
                        <SocialProof
                            {...socialProof}
                            audioUrl={socialProof.audio?.data?.attributes.url}
                            audioMoreInfo={socialProof.audioMoreInfo?.data}
                        />
                    )}
                    {providersSlider && <ProviderList {...providersSlider} />}
                    {stepByStep && <StepByStep {...stepByStep} />}

                    {cardsSlider && (
                        <CardSliderWithImageBackground {...cardsSlider} />
                    )}

                    {therapyOrCoach.data && (
                        <TherapyOrCoach
                            data={therapyOrCoach.data.attributes.component[0]}
                        />
                    )}

                    {pricing && (
                        <ChapterPricing
                            {...pricing}
                            desktopImage={
                                pricingDesktopImage
                                    ? pricingDesktopImage.src.data
                                          .attributes.url
                                    : undefined
                            }
                            desktopSharpImage={
                                pricingDesktopImage
                                    ? pricingDesktopImage.src.data
                                          .attributes.urlSharp
                                    : undefined
                            }
                            mobileImage={
                                pricingMobileImage
                                    ? pricingMobileImage.src.data
                                          .attributes.url
                                    : undefined
                            }
                            mobileSharpImage={
                                pricingDesktopImage
                                    ? pricingDesktopImage.src.data
                                          .attributes.urlSharp
                                    : undefined
                            }
                        />
                    )}

                    <div className="Chapter--KeyFeaturesSlider">
                        <KeyFeaturesSlider list={aepsyUsp} />
                    </div>

                    {faq.data && (
                        <Section container="short" spacingTop="m">
                            <TitleGroup
                                title={faq.data.attributes.title}
                                subtitle={faq.data.attributes.subtitle}
                                align="desktopCenter"
                            />
                            <Section spacing="s">
                                <SingleAccordion
                                    data={faq.data.attributes.qar.map(
                                        (item: any) => ({
                                            ...item,
                                            isMarkdown: true,
                                        })
                                    )}
                                    comingFrom="chapter"
                                />
                            </Section>
                        </Section>
                    )}

                    {relatedContentTitle && (
                        <Section container="large" spacingBottom="m">
                            <RelatedContent
                                title={relatedContentTitle}
                                cards={relatedContentCards}
                            />
                        </Section>
                    )}
                </div>

                <Section container="large" spacingBottom="m" spacingTop="s">
                    <VideoBackgroundWithContent />
                </Section>
                <Footer />
            </div>
        </Layout>
    );
};

export default withLocale(ChapterPage);

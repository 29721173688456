import React, { useState } from 'react';
import CardSlider from '../CardSlider/CardSlider';
import { RiVipDiamondLine } from 'react-icons/ri';
import { SwiperSlide } from 'swiper/react';
import CardWithIcon from '../Card/CardWithIcon';
import Modal from '../Modal/Modal';
import Text from '@atoms/Text/Text';
import './KeyFeaturesSlider.scss';

interface Props {
    list: any[];
}

const KeyFeaturesSlider: React.FC<Props> = ({ list }) => {
    const [modalContent, setModalContent] = useState(null);

    return (
        <div className="KeyFeaturesSlider">
            <CardSlider
                sliderOption={{
                    autoplay: true,
                    slidesPerView: 1.3,
                    a11y: false,
                    preventClicksPropagation: true,
                    threshold: 20,
                    breakpoints: {
                        601: {
                            slidesPerView: 2.3,
                            spaceBetween: 16,
                        },
                        901: {
                            centeredSlides: false,
                            slidesPerView: 3.3,
                        },
                        1101: {
                            centeredSlides: false,
                            slidesPerView: list.length <= 4 ? 4 : 4.3,
                        },
                    },
                }}
            >
                {list.map((item: any, index: number) => (
                    <SwiperSlide
                        key={index}
                        className={index === 0 ? 'first-child' : ''}
                        onClick={() => {
                            setModalContent(item);
                        }}
                    >
                        <CardWithIcon
                            icon={item.icon || <RiVipDiamondLine />}
                            title={item.title}
                            subtitle={item.subtitle}
                            clickable={item.text ? true : false}
                        />
                    </SwiperSlide>
                ))}
            </CardSlider>
            {modalContent && modalContent.text ? (
                <Modal
                    isOpen={Boolean(modalContent)}
                    title={modalContent && modalContent.title}
                    onClose={() => setModalContent(null)}
                >
                    <Text size="s">{modalContent && modalContent.text}</Text>
                </Modal>
            ) : (
                <></>
            )}
        </div>
    );
};

export default KeyFeaturesSlider;
